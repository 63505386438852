<template>
  <div class="error-box-404">
    <div>
      <h1>404</h1>
      <h3 class="center">
        {{ $t('errorLog.errorTypes.404') }}
        <a href="javascript:history.back()">{{ $t('back') }}</a>
      </h3>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
body .error-box-404 {
  background-color: #f9f9f9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 100pt;
  }

  h3 {
    display: inline;
  }
}
</style>
